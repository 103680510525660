<template>
  <div
    class="plat-adv-container"
    v-lazy:background-image="{src: require('../../../assets/images/bg4.png')}"
  >
    <div class="page-outter">
      <div align="center">
        <img
          class="plat-adv-title"
          v-lazy="require('../../../assets/images/title12.png')"
        />
        <div class="plat-adv-inner">
          <div
            class="plat-adv-dd"
            v-for="(item,index) in tds"
            :key="index"
          >
            <div class="plat-adv-item">
              <img v-lazy="item.icon" />
            </div>
            <span class="plat-adv-item-title">
              {{item.title}}
            </span>
            <span
              class="plat-adv-item-s1"
              v-html="item.name"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformAdvantage-sr",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon23.png"),
          title: "医药电商",
          name: `支持药店在平台搭建线上商城<br>可对接平台多端流量<br>拓宽销售渠道`,
        },
        {
          icon: require("../../../assets/images/app-icon24.png"),
          title: "采购管理",
          name: `可直接向平台入驻的<br>中心仓库发起采购申请<br>快速获取全面药品资源`,
        },
        {
          icon: require("../../../assets/images/app-icon25.png"),
          title: "库存管理",
          name: `库存明细数字化，支持库存盘点<br>出入库、货位调整等<br>智能药品过期/缺货预警<br>可一键生成采购单`,
        },
        {
          icon: require("../../../assets/images/app-icon26.png"),
          title: "会员管理",
          name: `可实现对线上线下会员的<br>统一管理<br>通过平台实现会员分析<br>会员服务，激发会员价值`,
        },
        {
          icon: require("../../../assets/images/app-icon27.png"),
          title: "营销管理",
          name: `可作为药店营销传播渠道<br>以及与客户的沟通互动平台<br>提升用户粘性`,
        },
        {
          icon: require("../../../assets/images/app-icon28.png"),
          title: "首营资料",
          name: `支持在线首营电子资料交换<br>平台技术符合国家要求标准<br>保证用户的信息安全`,
        },
        {
          icon: require("../../../assets/images/app-icon29.png"),
          title: "门店管理",
          name: `总部可在线设置门店权限<br>下发管理方案，查询经营情况<br>生成详细销售报表`,
        },
        {
          icon: require("../../../assets/images/app-icon30.png"),
          title: "收银管理",
          name: `支持多种互联网支付方式<br>灵活退换货管理`,
        },
        {
          icon: require("../../../assets/images/app-icon4.png"),
          title: "大数据分析",
          name: `深入分析药品销售数据、客户<br>行为画像等，可与行业数据<br>横向比对，为药店定价促销等<br>运营提供数据支持`,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.plat-adv-container {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  width: 100%;
  padding-bottom: 2.4rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.plat-adv-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.plat-adv-desc {
  display: block;
  font-size: 0.3rem;
  color: #d7d8d7;
  margin-top: 0.2rem;
}
.plat-adv-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.plat-adv-dd {
  width: 5.1rem;
  margin-top: 1.4rem;
}
.plat-adv-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid #dcdadd;
  border-radius: 2.4rem;
  img {
    width: 1.6rem;
  }
}
.plat-adv-item-title {
  display: block;
  font-size: 0.4rem;
  color: #fff;
  margin-top: 0.45rem;
}
.plat-adv-item-s1 {
  display: block;
  font-size: 0.36rem;
  color: #dcdadd;
  line-height: 1.7;
  margin-top: 0.26rem;
}
</style>